@font-face {
  font-family: "Gilroy-Black";
  src: url("./fonts/Gilroy-Black.woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url("./fonts/Gilroy-Bold.woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Extra-Bold";
  src: url("./fonts/Gilroy-ExtraBold.woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Semi-Bold";
  src: url("./fonts/Gilroy-SemiBold.woff");
  font-style: normal;
  font-display: swap;
  font-weight: normal;
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("./fonts/Gilroy-Regular.woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("./fonts/Gilroy-Medium.woff");
  font-style: normal;
  font-display: swap;
  font-weight: normal;
}

body {
  font-family: "Gilroy", "Gilroy-Black", "Gilroy-BlackItalic", "Gilroy-Bold",
    "Gilroy-Regular", "Gilroy-Medium", "Gilroy-Extra-Bold", "Gilroy-Semi-Bold";

  max-width: 1950px;
  margin: auto !important;
}

:root {
  --bg-primary: #030606;
  --bg-primary-reverse: #fcfcfc;
  --bg-secondary: #181818;
  --bg-chip: #161a18;
  --bg-dropdown: #161a18;
  --bg-green: #00787a;
  --bg-green-gradient: linear-gradient(90deg, #007a78 0%, #2da499 100%);
  --bg-green-gradient-reverse: linear-gradient(
    270deg,
    #007a78 0%,
    #2da499 100%
  );
  --bg-edit-profile: #161a18;
  --bg-loader: #fff;
  --bg-filter-select: #161a18;
  --bg-header-footer: #0306068c;
  --bg-filter-cat-chip: #161a18;
  --bg-home-cat-chip: #030606;
  --bg-login-sign: #141416;
  --bg-google-btn: #fdfdfd;
  --bg-auth-err-msg: #303030;
  --bg-card: #18191d;
  --bg-card2: #030606;
  --bg-card-no-opacity: #18191d;
  --bg-input-check-radio: #bfbfbf;
  --bg-black-50-opacity: rgba(24, 25, 29, 0.5);
  --bg-modal: #161a18;
  --bg-modal-btn-hover: #18191d;
  --bg-home-know-card: rgba(24, 25, 29, 0.5);
  --bg-home-slider-blur: linear-gradient(
    180deg,
    #010303 18.14%,
    rgba(1, 3, 3, 0) 100%
  );
  --bg-btn-lock: rgba(80, 80, 80, 0.5);
  /* font size */
  --fs-80: 5rem;
  --fs-72: 4.5rem;
  --fs-60: 3.75rem;
  --fs-50: 3.125rem;
  --fs-48: 3rem;
  --fs-46: 2.875rem;
  --fs-44: 2.75rem;
  --fs-42: 2.625rem;
  --fs-40: 2.5rem;
  --fs-34: 2.125rem;
  --fs-32: 2rem;
  --fs-30: 1.875rem;
  --fs-28: 1.75rem;
  --fs-26: 1.625rem;
  --fs-24: 1.5rem;
  --fs-22: 1.375rem;
  --fs-20: 1.25rem;
  --fs-18: 1.125rem;
  --fs-16: 1rem;
  --fs-14: 0.875rem;
  --fs-12: 0.75rem;

  /* font family  */

  --ff-gilroy-regular: "Gilroy-Regular";
  --ff-gilroy-black: "Gilroy-Black";
  --ff-gilroy-bold: "Gilroy-Bold";
  --ff-gilroy-medium: "Gilroy-Medium";
  --ff-gilroy-extra-bold: "Gilroy-Extra-Bold";
  --ff-gilroy-semi-bold: "Gilroy-Semi-Bold";

  /* font color  */
  --fc-primary: #f3f3f3;
  --fc-secondary: #282828;
  --fc-green: #00787a;
  --fc-green2: #f3f3f3;
  --fc-black2: #bfbfbf;
  --fc-disabletext: rgba(191, 191, 191, 0.4);
  --fc-error-btn: #212121;
  /* margin*/
  --mrl-80: 0px 80px;
  --mrl-20: 0px 20px;
  --mt-20: 2em;
  --mb-20: 2em;

  /*padding*/
  --pt-10: 1em;
  --pb-10: 1em;
  --pl-10: 1em;
  --pr-10: 1em;

  /*hover*/
  --bg-hover-primary: #0e1110;
  /*accent color*/
  --a-clr: #007a78;

  /* horizontal line color  */
  --clr-line: #bfbfbf;
  --line-color1: #f3f3f3;

  /* background  */
  --bg-layout: hwb(150 4% 95% / 0.85);
  --bg-layout2: #18191d;
  --bg-instructor: rgba(10, 12, 11, 0.5);
  --bg-optionbar: rgba(22, 26, 24, 0.5);
  --bg-assessmenttop: #18191d;
  --bg-assesmentbottm: rgba(24, 25, 29, 0.6);
  --bg-assessmentMobbtm: rgba(10, 12, 11, 0.85);
  --bg-assessmentScoreCard: linear-gradient(
    180deg,
    #18191d 1.45%,
    rgba(14, 17, 16, 0.0105699) 99.99%,
    rgba(14, 17, 16, 0.1) 100%
  );
  --bg-assessmenttablebtm: rgba(24, 25, 29, 0.65);
  --bg-instructorProfileBg: #090a09;
  --bg-reviewcard: #18191d80;
  --bg-adminrply: #18191d;
  --bg-editprofileinner: #070707;
  --bg-editprofileOuter: #18191d;
  --bg-discussionInput: #070707;
  --bg-cartpopup: #0a0c0b;
  --bg-error-btn: #ffffff;
  /*shadow*/
  --shadow-cards: 0px 4px 8px rgba(0, 0, 0, 0.5);
  --shadow-primary: 0px 1px 8px rgba(0, 0, 0, 0.5);
  --shadow-header-footer: 0px 5px 20px #161818;
  --shadow-new-footer: 0px -5px 20px #161818;
  --shadow-button: 0px 4px 8px rgba(0, 0, 0, 0.5);

  --shadow-statusbar: 0px 2px 15px 2px rgb(0 0 0 / 0.25);
  --shaow-coupon-modal-card: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-othercatagory: 5px 5px 0px #ffffff;
  --shadow-markasdone: 0px 4px 8px rgba(0, 0, 0, 0.5);
  /*border*/
  --bord-primary: 1px solid #f3f3f3;
  --bord-secondary: 1px solid #282828;
  --bord-left-coupon: 8px solid
    linear-gradient(180.01deg, #007a78 1.26%, #2da499 98.51%);
  --bord-bottom-1: 2px solid #f3f3f3;
  --bord-gradient: 1px solid
    linear-gradient(180.01deg, #007a78 1.26%, #2da499 98.51%);
  --bord-bottom-lessonlist: #070707;
  --bord-instructor-profilePic: 2px solid
    linear-gradient(
      360deg,
      #0b0b0b 100%,
      #33aa9d 100%,
      #3eaf7c 100%,
      #007a78 100%
    );

  /*media query break point*/

  --mq-mob: 490px;
  --mq-tab: 768px;
  --mq-sm-lap: 1024px;
}
